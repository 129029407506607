// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-60-tals-musikquiz-js": () => import("../src/pages/60-tals-musikquiz.js" /* webpackChunkName: "component---src-pages-60-tals-musikquiz-js" */),
  "component---src-pages-70-tals-musikquiz-js": () => import("../src/pages/70-tals-musikquiz.js" /* webpackChunkName: "component---src-pages-70-tals-musikquiz-js" */),
  "component---src-pages-80-tals-musikquiz-js": () => import("../src/pages/80-tals-musikquiz.js" /* webpackChunkName: "component---src-pages-80-tals-musikquiz-js" */),
  "component---src-pages-90-tals-musikquiz-js": () => import("../src/pages/90-tals-musikquiz.js" /* webpackChunkName: "component---src-pages-90-tals-musikquiz-js" */),
  "component---src-pages-bidra-js": () => import("../src/pages/bidra.js" /* webpackChunkName: "component---src-pages-bidra-js" */),
  "component---src-pages-cookiepolicy-js": () => import("../src/pages/cookiepolicy.js" /* webpackChunkName: "component---src-pages-cookiepolicy-js" */),
  "component---src-pages-danska-snapsvisor-js": () => import("../src/pages/danska-snapsvisor.js" /* webpackChunkName: "component---src-pages-danska-snapsvisor-js" */),
  "component---src-pages-finska-snapsvisor-js": () => import("../src/pages/finska-snapsvisor.js" /* webpackChunkName: "component---src-pages-finska-snapsvisor-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-musikquiz-2000-talet-js": () => import("../src/pages/musikquiz-2000-talet.js" /* webpackChunkName: "component---src-pages-musikquiz-2000-talet-js" */),
  "component---src-pages-musikquiz-for-aldre-js": () => import("../src/pages/musikquiz-for-aldre.js" /* webpackChunkName: "component---src-pages-musikquiz-for-aldre-js" */),
  "component---src-pages-musikquiz-for-barn-js": () => import("../src/pages/musikquiz-for-barn.js" /* webpackChunkName: "component---src-pages-musikquiz-for-barn-js" */),
  "component---src-pages-musikquiz-hiphop-js": () => import("../src/pages/musikquiz-hiphop.js" /* webpackChunkName: "component---src-pages-musikquiz-hiphop-js" */),
  "component---src-pages-musikquiz-jul-js": () => import("../src/pages/musikquiz-jul.js" /* webpackChunkName: "component---src-pages-musikquiz-jul-js" */),
  "component---src-pages-musikquiz-rock-js": () => import("../src/pages/musikquiz-rock.js" /* webpackChunkName: "component---src-pages-musikquiz-rock-js" */),
  "component---src-pages-musikquiz-sommarplagor-js": () => import("../src/pages/musikquiz-sommarplagor.js" /* webpackChunkName: "component---src-pages-musikquiz-sommarplagor-js" */),
  "component---src-pages-musikquiz-js": () => import("../src/pages/musikquiz.js" /* webpackChunkName: "component---src-pages-musikquiz-js" */),
  "component---src-pages-ovriga-musikquiz-js": () => import("../src/pages/ovriga-musikquiz.js" /* webpackChunkName: "component---src-pages-ovriga-musikquiz-js" */),
  "component---src-pages-populara-musikquiz-js": () => import("../src/pages/populara-musikquiz.js" /* webpackChunkName: "component---src-pages-populara-musikquiz-js" */),
  "component---src-pages-populara-snapsvisor-js": () => import("../src/pages/populara-snapsvisor.js" /* webpackChunkName: "component---src-pages-populara-snapsvisor-js" */),
  "component---src-pages-skriv-ut-index-js": () => import("../src/pages/skriv-ut/index.js" /* webpackChunkName: "component---src-pages-skriv-ut-index-js" */),
  "component---src-pages-snapsvisor-jul-js": () => import("../src/pages/snapsvisor-jul.js" /* webpackChunkName: "component---src-pages-snapsvisor-jul-js" */),
  "component---src-pages-snapsvisor-kraftskiva-js": () => import("../src/pages/snapsvisor-kraftskiva.js" /* webpackChunkName: "component---src-pages-snapsvisor-kraftskiva-js" */),
  "component---src-pages-snapsvisor-midsommar-js": () => import("../src/pages/snapsvisor-midsommar.js" /* webpackChunkName: "component---src-pages-snapsvisor-midsommar-js" */),
  "component---src-pages-snapsvisor-nyar-js": () => import("../src/pages/snapsvisor-nyar.js" /* webpackChunkName: "component---src-pages-snapsvisor-nyar-js" */),
  "component---src-pages-snapsvisor-pask-js": () => import("../src/pages/snapsvisor-pask.js" /* webpackChunkName: "component---src-pages-snapsvisor-pask-js" */),
  "component---src-pages-snapsvisor-js": () => import("../src/pages/snapsvisor.js" /* webpackChunkName: "component---src-pages-snapsvisor-js" */),
  "component---src-pages-svenska-musikquiz-js": () => import("../src/pages/svenska-musikquiz.js" /* webpackChunkName: "component---src-pages-svenska-musikquiz-js" */)
}

